import axios from 'axios';
import * as types from './actionTypes';

export function setVoucherTypesAction(actionType, voucherTypes) {
  return { type: actionType, voucherTypes };
}

export function beginLoadVouchers() {
  return { type: types.BEGIN_LOAD_VOUCHERS };
}

export function finishLoadVouchers(vouchers) {
  return { type: types.FINISH_LOAD_VOUCHERS, vouchers };
}

export function beginFilterVouchers() {
  return { type: types.BEGIN_FILTER_VOUCHERS };
}

export function finishFilterVouchers(vouchers) {
  return { type: types.FINISH_FILTER_VOUCHERS, vouchers };
}


export function setCountryFilterOptionsAction(countries) {
  return { type: types.SET_COUNTRY_FILTER_OPTIONS, countries };
}

export function setCountryFilterOptions(thirdPartyCatalog) {
  return (dispatch) => {
    const countries = thirdPartyCatalog.content
      .map(thirdPartyProduct => ({
        label: thirdPartyProduct.country,
        value: thirdPartyProduct.countryCode,
      }))
      .reduce((acc, curr) => {
        return acc.some(country => country.value === curr.value) ? acc : [...acc, curr];
      }, []);

    dispatch(setCountryFilterOptionsAction(countries));
  };
}

export function loadVouchersForOrder(
  orderId,
  search = '',
  filters = { typeName: { value: 'all' }, country: [] },
  size = 50,
  page = 0,
  sort = { sortBy: 'voucherName', sortDir: 'desc' },
) {
  return (dispatch) => {
    dispatch(beginLoadVouchers());
    let voucherProductTypeFilter = '';
    let countryFilter = '';
    if (filters.typeName && typeof filters.typeName.value !== 'undefined' && filters.typeName.value !== 'all') {
      voucherProductTypeFilter = `&voucherProductType=${filters.typeName.value}`;
    }
    if (filters.country && filters.country.length > 0) {
      countryFilter = filters.country.map(countryIterator => `&country=${countryIterator.value}`).join('');
    }
    const searchParam = search && `&voucherProductName=${encodeURIComponent(search)}`;
    const pageParams = `page=${page}&size=${size}`;
    const sortParam = `&sort=${sort.sortBy},${sort.sortDir}`;
    const url = `/venom/api/orders/${orderId}/voucherCatalogs?${pageParams}${sortParam}${searchParam}${countryFilter}${voucherProductTypeFilter}`;

    return axios.get(url)
      .then(response => response.data, () => [])
      .then((data) => {
        const { content, ...rest } = data;
        dispatch(finishLoadVouchers(content));
        return rest;
      });
  };
}

export function loadVoucherProductTypes(type) {
  let url;
  if (type) {
    url = `/venom/api/productTypes?agreementType=${type}`;
  } else {
    url = '/venom/api/productTypes';
  }

  return async (dispatch) => {
    try {
      const vpTypes = await axios.get(url).then(response => response.data);
      const voucherTypes = vpTypes
        .map(vpType => ({
          label: vpType.name,
          value: vpType.enumCode,
        }))
        .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

      let actionType;
      switch (type) {
        case 'agency':
          actionType = types.SET_AGENCY_VOUCHER_TYPES;
          break;
        case 'firstPartyPublisher':
          actionType = types.SET_FIRST_PARTY_PUBLISHER_VOUCHER_TYPES;
          break;
        case undefined:
          actionType = types.SET_ALL_VOUCHER_TYPES;
          break;
        default:
          break;
      }

      if (actionType) {
        dispatch(setVoucherTypesAction(actionType, voucherTypes));
      }
    } catch (error) {
      // TODO: error handling
      // () => dispatch(vouchersHasErrored(true))
    }
  };
}

export function setFilter(filterType, filterItems) {
  return (dispatch) => {
    dispatch({
      type: types.SET_THIRD_PARTY_CATALOG_FILTER,
      filterType,
      filterItems,
    });
  };
}

export function resetFilters() {
  return {
    type: types.RESET_THIRD_PARTY_CATALOG_FILTERING,
  };
}
