import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Layout,
  Flex,
} from '@partner-global-ui/components';
import './Partner.scss';
import PartnerInfoComponent from './PartnerInfo';
import PartnerDetailAgreementComponent from './partnerDetailAgreements/PartnerDetailAgreements';
import PartnerDetailUsersComponent from './partnerDetailUsers/PartnerDetailUsers';
import PartnerDetailSFTPComponent from './partnerDetailSFTP/PartnerDetailSFTP';
import * as partnerActions from '../../actions/partnerActions';
import hasPermission from '../../utils/accessControl/hasPermission';
import roleKeys from '../../utils/accessControl/roleKeys';
import userIsPartnerRequesterUtils from '../../utils/userIsPartnerRequester.utils';
import * as types from '../../actions/actionTypes';
import PartnerTypes from '../../constants/integratorPartnerTypes.constants';
import NotFoundError from '../error/NotFound';

export const PartnerPage = (props) => {
  const [unauthorized, setUnauthorized] = useState(false);

  const {
    match, partner, actions, history, partnerSftps,
  } = props;

  const {
    newPartner, loadPartner, clearAgreements,
  } = actions;

  useEffect(() => {
    if (typeof match.params.id !== 'undefined') {
      loadPartner(match.params.id)
        .then((res) => {
          if (typeof res !== 'undefined' && res.ok && typeof partner === 'undefined') {
            history.push('/partner');
          }
        })
        .catch(() => {
          setUnauthorized(true);
        });
    } else {
      newPartner();
    }

    return () => {
      clearAgreements();
    };
  }, []);

  const renderNav = () => {
    return (
      <div name="partnerNav" className="partner-nav">
        <Container>
          <Layout>
            <Flex
              id="partnerLabel"
              className="title"
              colSpan="10"
            >
              {i18next.t('msg_codes_accountType_partner')}
            </Flex>
          </Layout>
        </Container>
      </div>
    );
  };

  if (unauthorized) {
    return (<NotFoundError />);
  }

  return (
    <div name="partnerGrid" className="partner">
      {renderNav()}
      <Container className="partner-page">
        <Layout name="partnerInfoComponentRow" className="partner-info">
          <PartnerInfoComponent
            name="partnerInfoComponent"
            {...props}
            partner={partner}
          />
        </Layout>
        { typeof match.params.id !== 'undefined'
            && (
              <>
                <Layout name="partnerUsersRow" className="partner-users">
                  <PartnerDetailUsersComponent
                    name="partnerDetailUsersComponent"
                    {...props}
                    partner={partner}
                  />
                </Layout>
                <Layout name="partnerAgreementRow" className="partner-agreements">
                  <PartnerDetailAgreementComponent
                    name="partnerDetailAgreementComponent"
                    {...props}
                    partner={partner.partner}
                  />
                </Layout>
                {partner.partnerType !== PartnerTypes.THIRD_PARTY && (
                  <Layout name="partnerSFTPRow" className="partner-sftps">
                    <PartnerDetailSFTPComponent
                      name="PartnerDetailSFTPComponent"
                      {...props}
                      partner={partner}
                      sftpList={partnerSftps}
                    />
                  </Layout>
                )}
              </>
            )
          }
      </Container>
    </div>
  );
};

PartnerPage.propTypes = {
  partner: PropTypes.object,
  partnerSftps: PropTypes.array,
  actions: PropTypes.objectOf(PropTypes.func),
  canEdit: PropTypes.bool,
  clearAgreements: PropTypes.func,
};

PartnerPage.defaultProps = {
  partner: {},
  partnerSftps: [],
  actions: {},
  canEdit: false,
  clearAgreements: () => {},
};

export function mapStateToProps(state) {
  const canEdit = !userIsPartnerRequesterUtils(state.user)
  && hasPermission(state, roleKeys.partner.create);

  return {
    canEdit,
    partner: state.partner.partner,
    partnerSftps: state.partner.partnerSftpMapList,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(partnerActions, dispatch),
    // remove in 3.1
    clearAgreements: () => { dispatch({ type: types.CLEAR_AGREEMENTS }); },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerPage));
