import roleKeys from '../roleKeys';

export default {
  env: 'p1-spint',
  adobe: '//assets.adobedtm.com/3dc13bcabc29/a4d9cd7252f2/launch-e12153e205bb-development.min.js',
  'thirdPartyCatalog.searchSku': false,
  // prodline disabled
  [roleKeys.thirdParty.viewCatalog]: false,
  [roleKeys.codeProducts.viewList]: false,
  [roleKeys.codeProduct.viewDetail]: false,
  [roleKeys.order.viewDetail]: false,
  [roleKeys.order.viewList]: false,
  [roleKeys.agreement.viewDetail]: false,
  [roleKeys.agreement.viewList]: false,
  [roleKeys.partner.viewDetail]: false,
  [roleKeys.partner.viewList]: false,
  [roleKeys.partner.viewThirdPartyAgreements]: true,
  [roleKeys.voucherTypes.viewList]: true,
  [roleKeys.voucherBatches.viewList]: true,
  [roleKeys.voucherTypes.viewDetail]: true,
  [roleKeys.voucherBatches.viewDetail]: true,
  i18nDebug: false,
  feesUrl: 'https://learn.playstation.net/csh?context=3P_Fees',
  helpUrl: 'https://learn.playstation.net/csh?context=3P_Welcome',
  downloadHelpUrl: 'https://learn.playstation.net/csh?context=3P_Download',
  startEndDateUrl: 'https://learn.playstation.net/csh?context=Start_End_Date',
  contentPipelineUrl: 'https://publish.playstation.net',
  mainPageOverride: {
    component: 'VoucherTypes',
    permission: roleKeys.voucherTypes.viewList,
  },
};
