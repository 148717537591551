export default {
  order: {
    viewList: 'order.viewList',
    viewAgreementType: 'order.viewAgreementType',
    viewLineErrors: 'order.viewLineErrors',
    viewThirdPartyLineErrors: 'order.viewThirdPartyLineErrors',
    searchList: 'order.searchList',
    viewSearchListPartnerName: 'order.viewSearchListPartnerName',
    viewDetail: 'order.viewDetail',
    create: 'order.create',
    cancel: 'order.cancel',
    editReference: 'order.editReference',
    editContractingPartner: 'order.editContractingPartner',
    editContractingSIERegion: 'order.editContractingSIERegion',
    editSftpId: 'order.editSftpId',
    editAdditionalRedemptionCheck: 'order.editAdditionalRedemptionCheck',
    editManufacturingCostCurrency: 'order.editManufacturingCostCurrency',
    editFreightCost: 'order.editFreightCost',
    editFreightCostTax: 'order.editFreightCostTax',
    addPurchaseOrderNumber: 'order.addPurchaseOrderNumber',
    editPurchaseOrderNumber: 'order.editPurchaseOrderNumber',
    viewPurchaseOrderNumber: 'order.viewPurchaseOrderNumber',
    editComments: 'order.editComments',
    addApprovalComments: 'order.addApprovalComments',
    viewApprovalComments: 'order.viewApprovalComments',
    approvalActions: 'order.approvalActions',
    hasOrderRegions: 'order.hasOrderRegions',
    canSeeSaveButton: 'order.canSeeSaveButton',
    canInitialApprove: 'order.canInitialApprove',
    editOrderFee: 'order.editOrderFee',
    editAdditionalCost: 'order.editAdditionalCost',
  },
  permittedUsesOrder: {
    viewDetail: 'permittedUsesOrder.viewDetail',
    canSave: 'permittedUsesOrder.canSave',
    canApprove: 'permittedUsesOrder.canApprove',
    downloadPO: 'permittedUsesOrder.downloadPO',
    editApplyFeeWaiver: 'permittedUsesOrder.editApplyFeeWaiver',
    viewComment: 'permittedUsesOrder.viewComment',
    editUseCount: 'permittedUsesOrder.editUseCount',
  },
  orderContent: {
    addLineItem: 'orderContent.addLineItem',
    removeLineItem: 'orderContent.removeLineItem',
    cancelLineItem: 'orderContent.cancelLineItem',
    activateLineItem: 'orderContent.activateLineItem',
    downloadLineItem: 'orderContent.downloadLineItem',
    retryFulfillment: 'orderContent.retryFulfillment',
    retryActivation: 'orderContent.retryActivation',
    viewLineErrors: 'orderContent.viewLineErrors',
    editManufactureCost: 'orderContent.editManufactureCost',
    editTax: 'orderContent.editTax',
    editItemReference: 'orderContent.editItemReference',
    editQuantity: 'orderContent.editQuantity',
    editItem: 'orderContent.editItem',
    viewStartDate: 'orderContent.viewStartDate',
    viewEndDate: 'orderContent.viewEndDate',
    editBranded: 'orderContent.editBranded',
  },
  orderDocuments: {
    viewList: 'orderDocuments.viewList',
    viewFileHash: 'orderDocuments.viewFileHash',
    viewShareWithPartner: 'orderDocuments.viewShareWithPartner',
    viewRemoveTooltip: 'orderDocuments.viewRemoveTooltip',
    uploadAttachment: 'orderDocuments.uploadAttachment',
    canDownload: 'orderDocuments.canDownload',
  },
  freeAllocationOrderContent: {
    viewDetail: 'freeAllocationOrderContent.viewDetail',
    activateLineItem: 'freeAllocationOrderContent.activateLineItem',
    downloadLineItem: 'freeAllocationOrderContent.downloadLineItem',
    cancelLineItem: 'freeAllocationOrderContent.cancelLineItem',
    retryLineItem: 'freeAllocationOrderContent.retryLineItem',
    viewFulfillmentError: 'freeAllocationOrderContent.viewFulfillmentError',
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: 'permittedUsesOrderContent3P.downloadLineItem',
    retryLineItem: 'permittedUsesOrderContent3P.retryLineItem',
    cancelLineItem: 'permittedUsesOrderContent3P.cancelLineItem',
    editLineDateTime: 'permittedUsesOrderContent3P.editLineDateTime',
    canSwapCodeProduct: 'permittedUsesOrderContent3P.canSwapCodeProduct',
  },
  orderProductCatalog: {
    viewCatalog: 'orderProductCatalog.viewCatalog',
    viewAllProducts: 'orderProductCatalog.viewAllProducts',
    search: 'orderProductCatalog.search',
  },
  adminCatalog: {
    searchCatalog: 'adminCatalog.searchCatalog',
    viewDetail: 'adminCatalog.viewDetail',
  },
  partner: {
    viewList: 'partner.viewList',
    viewDetail: 'partner.viewDetail',
    viewThirdPartyAgreements: 'partner.viewThirdPartyAgreements',
    create: 'partner.create',
    editName: 'partner.editName',
    editStatus: 'partner.editStatus',
    viewThirdPartyPublisherAgreements: 'partner.viewThirdPartyPublisherAgreements',
  },
  user: {
    view: 'user.view',
    viewDetail: 'user.viewDetail',
    create: 'user.create',
    edit: 'user.edit',
    canSetSuperAdmin: 'user.canSetSuperAdmin',
    canSeePgpKeys: 'user.canSeePgpKeys',
    canAddPgpKeys: 'user.canAddPgpKeys',
    canRemovePgpKeys: 'user.canRemovePgpKeys',
    canDownloadPgpKeys: 'user.canDownloadPgpKeys',
    canSetupPgpKeys: 'user.canSetupPgpKeys',
    canSeeNoPgpKeyModalMessage: 'user.canSeeNoPgpKeyModalMessage',
  },
  agreement: {
    viewList: 'agreement.viewList',
    viewDetail: 'agreement.viewDetail',
    create: 'agreement.create',
    createThirdParty: 'agreement.createThirdParty',
    edit: 'agreement.edit',
    showAgreementVersionDetails: 'agreement.showAgreementVersionDetails',
  },
  thirdParty: {
    viewCatalog: 'thirdParty.viewCatalog',
    download: 'thirdParty.download',
    canSeeCart: 'thirdParty.canSeeCart',
    canSeeOrderHistory: 'thirdParty.canSeeOrderHistory',
    canSeeOrderHistorySuccess: 'thirdParty.canSeeOrderHistorySuccess',
    generate: 'thirdParty.generate',
    redownload: 'thirdParty.redownload',
    viewErrorMessage: 'thirdParty.viewErrorMessage',
    retry: 'thirdParty.retry',
    searchCatalog: 'thirdParty.searchCatalog',
    canEditUseCount: 'thirdParty.canEditUseCount',
  },
  mainPage: {
    componentName: 'mainPage.componentName',
  },
  voucherTypes: {
    viewList: 'voucherTypes.viewList',
    viewDetail: 'voucherTypes.viewDetail',
    viewReadyOnlyDetail: 'voucherTypes.viewReadyOnlyDetail',
    create: 'voucherTypes.create',
    edit: 'voucherTypes.edit',
  },
  access: {
    callbackGateway: 'access.callbackGateway',
  },
  voucherBatches: {
    viewList: 'voucherBatches.viewList',
    viewDetail: 'voucherBatches.viewDetail',
    create: 'voucherBatches.create',
    edit: 'voucherBatches.edit',
  },
  termsOfService: {
    canView: 'termsOfService.canView',
    viewCanceled: 'termsOfService.viewCanceled',
  },
  codeProduct: {
    createDetail: 'codeProduct.createDetail',
    viewDetail: 'codeProduct.viewDetail',
    editDetail: 'codeProduct.editDetail',
    viewEndDate: 'codeProduct.viewEndDate',
  },
  codeProducts: {
    viewList: 'codeProducts.viewList',
    create: 'codeProducts.create',
  },
  maintainanceDashboard: {
    viewPage: 'maintainanceDashboard.viewPage',
  },
  confirmation: {
    viewPage: 'confirmation.viewPage',
  },
};
