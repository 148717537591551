import React, {
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Button,
  Layout,
  Flex,
  Container,
  NotificationContext,
  MessageBoard,
  Tooltip,
  useClickOutside,
  AlertContext,
  ALERT_ACTIONS,
  Alert,
  STATUS,
} from '@partner-global-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { autoHide, timeout } from '../../../constants/notifications.constant';
import { VouchersSieSuperAdmin } from '../../../constants/userRoles.constants';
import { MONEY } from '../../../constants/voucherProductTypes.constants';
import * as orderActions from '../../../actions/orderActions';
import * as trackingActions from '../../../actions/trackingActions';
import { getSuccessBannerText } from './methods/navMethods';
import { checkIfLineFieldsChanged, removeTrailingZeros } from './methods/orderMethods';
import { addRemovedLineItems } from '../../../actions/orderLineCacheActions';
import { usePermission } from '../../../utils/accessControl/hasPermission';
import roleKeys from '../../../utils/accessControl/roleKeys';

const alphaNumeric = new RegExp(/^[a-z0-9]+$/i);

const hasOrderValueChanged = (val, field, initialValue) => {
  if (field === 'partnerSftpId') {
    return val === initialValue;
  }
  return typeof val === 'undefined' || val === '' || val === 'select' || val === null // has no value
    || field === 'modifyDate' // after saving, when modified date is different.
    || typeof val === 'object' // is object, not going to compare
    || typeof initialValue === 'undefined'; // object didn't exist on initial load
};

const Nav = ({
  order,
  setReadyToAdd,
  initialLoad,
  setInitialLoad,
  triggerInitialOrder,
  pageRef,
}) => {
  // Setup
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const notificationCont = useContext(NotificationContext);
  const user = useSelector(state => state.user);

  const [requestingAmendment, setRequestingAmendment] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [approving, setApproving] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [showMessageBoard, setShowMessageBoard] = useState('');
  const [readyToSave, setReadyToSave] = useState(false);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [readyToDownload, setReadyToDownload] = useState(true);

  const {
    savingOrder,
    submittingOrder,
    loading,
  } = useSelector(state => state);
  let {
    initialOrder,
  } = useSelector(state => state);

  const alertContext = useContext(AlertContext);

  // order prop destructuring
  const {
    orderStatus,
    currentApprovalLevel,
    maximumRequiredApprovalLevel,
    freightCost,
    freightCostTaxPercentage,
    totalManufactureFreightCostAndAdditionalCosts,
  } = order;

  // user prop destructuring
  const {
    approvalLevel: userApprovalLevel,
    roleCode,
  } = user;

  // Approval button permissions and display flags
  const canInitialApprove = usePermission(roleKeys.order.canInitialApprove, userApprovalLevel);
  const isWaitingForInitialApproval = typeof currentApprovalLevel === 'undefined';
  const userCanInitialApprove = isWaitingForInitialApproval && canInitialApprove;

  // User's approval level is below the current approval level
  const userBelowNextLevel = userApprovalLevel < currentApprovalLevel + 1;

  // An order is in final approval state when its maximum required approval
  //  level is above or equal to its current approval level.
  const orderIsInFinalApproval = currentApprovalLevel >= maximumRequiredApprovalLevel;

  // If the user's role is below the next level and the order is not in
  //  final approval, or the order is waiting for initial approval and the user
  //  can not give initial approval, then the action buttons should be disabled.
  const disableActionButtons = (userBelowNextLevel && !orderIsInFinalApproval)
    || (isWaitingForInitialApproval && !userCanInitialApprove);

  // Buttons & Status Permissions
  const isNew = order.newOrder;
  const isDraft = order.orderStatus === 'DRAFT_ORDER';
  const isDraftAmendment = order.orderStatus === 'PENDING_DRAFT_AMENDMENT';
  const isApproved = orderStatus && orderStatus === 'APPROVED_ORDER';
  const canBeApproved = orderStatus && orderStatus === 'SUBMITTED_PENDING_APPROVAL';
  const isRejected = order.orderStatus === 'REJECTED';
  const isCancelled = order.orderStatus === 'CANCELLED_ORDER';
  const canCreate = usePermission(roleKeys.order.create);
  const canApprove = usePermission(roleKeys.order.approvalActions);
  const canSeeCancelButton = usePermission(roleKeys.order.cancel, orderStatus);
  const canSeeSaveButton = usePermission(roleKeys.order.canSeeSaveButton);

  const savebuttonLabel = (isNew || isDraft || isDraftAmendment) ? t('msg_codes_save_as_draft') : t('msg_codes_cta_save');

  const showCancel = canSeeCancelButton && !orderIsInFinalApproval
  && !isApproved && !isRejected && !isCancelled;
  const showSave = canSeeSaveButton && !isRejected && !isCancelled;
  const showSubmit = canCreate && !isApproved && !canBeApproved && !isRejected && !isCancelled;
  const showApprove = canApprove && canBeApproved;

  const messageBoard = useRef(null);

  const notifyPayload = { autoHide, timeout };
  const notify = {
    success: message => notificationCont.dispatch({ type: 'add', payload: { ...notifyPayload, message, status: 'success' } }),
    error: message => notificationCont.dispatch({ type: 'add', payload: { ...notifyPayload, message, status: 'error' } }),
  };

  useEffect(() => {
    if (triggerInitialOrder) {
      setInitialLoad(true);
    }
  }, []);

  const openMessageBoard = useCallback((actionType) => {
    setShowMessageBoard(actionType);
    setWaiting(true);
  }, []);

  const closeMessageBoard = useCallback(() => {
    setShowMessageBoard('');
    setWaiting(false);
  }, []);

  useClickOutside(messageBoard, () => {
    closeMessageBoard();
  });

  const handleRequestAmendment = useCallback(
    async (message) => {
      const { orderNumber, applyFeeWaiver } = order;
      setRequestingAmendment(true);

      try {
        const result = await orderActions.amendOrder(
          orderNumber,
          message,
          applyFeeWaiver,
        )(dispatch);
        if (
          typeof result !== 'undefined'
          && result.response.data.detailMessages[0].includes('comment: must not be blank')
        ) {
          notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
          closeMessageBoard();
        } else {
          closeMessageBoard();
          notify.success(t('msg_codes_banner_amendmentRequested'));
          history.push('/orders');
          dispatch(
            trackingActions.fireEvent(
              'order-click',
              'click',
              'empty',
              'reject',
              'order-submission',
              '1',
              'button',
            ),
          );
        }
      } catch (error) {
        notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
      }

      setRequestingAmendment(false);
    },
    [order],
  );

  const handleReject = useCallback(
    async (message) => {
      const { orderNumber, applyFeeWaiver } = order;
      setRejecting(true);

      try {
        const result = await orderActions.rejectOrder(
          orderNumber,
          message,
          applyFeeWaiver,
        )(dispatch);
        if (
          typeof result !== 'undefined'
          && result.response.data.detailMessages[0].includes('comment: must not be blank')
        ) {
          notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
          closeMessageBoard();
        } else {
          closeMessageBoard();
          notify.success(t('msg_codes_banner_orderRejected'));
          history.push('/orders');
          dispatch(
            trackingActions.fireEvent(
              'order-click',
              'click',
              'empty',
              'reject',
              'order-submission',
              '1',
              'button',
            ),
          );
        }
      } catch (error) {
        notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
      }

      setRejecting(false);
    },
    [order],
  );

  const handleApprove = useCallback(async () => {
    setApproving(true);
    setWaiting(true);

    try {
      const approvedOrder = await orderActions.approveOrder(order)(dispatch);

      const successBannerText = getSuccessBannerText({
        userApprovalLevel,
        maximumRequiredApprovalLevel,
        isWaitingForInitialApproval,
        isSuperAdmin: roleCode === VouchersSieSuperAdmin.roleCode,
        approvedOrder,
      });

      notify.success(successBannerText);
      history.push('/orders');
      dispatch(
        trackingActions.fireEvent(
          'order-click',
          'click',
          'empty',
          'approve',
          'order-submission',
          '1',
          'button',
        ),
      );
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
    } finally {
      setApproving(false);
      setWaiting(false);
    }
  }, [order]);

  const renderActionMessage = (actionType, button) => {
    const action = actionType === 'amend' ? handleRequestAmendment
      : actionType === 'reject' && handleReject;

    return (
      <div className="action-button">
        {button}
        {showMessageBoard === actionType && (
          <div
            ref={messageBoard}
            className={`message-board-container ${actionType}`}
            data-testid="message-board-container"
          >
            <MessageBoard
              id="message-board"
              title={t('msg_codes_orderDetailsAdmin_msgboard_message')}
              statusText={t('msg_codes_orderDetailsAdmin_msgboard_note')}
              statusIcon="ico-info"
              statusIconColor="info"
              onSend={action}
              onCancel={closeMessageBoard}
              onClose={closeMessageBoard}
              date={moment(order.modifyDate).format('MM/DD/YY hh:mm a')}
              placeholder={t('msg_codes_orderDetailsAdmin_msgboard_enterComments')}
              cancelButtonText={t('msg_codes_cta_cancel')}
              sendButtonText={t('msg_codes_orderDetailsAdmin_msgboard_send_cta')}
            />
          </div>
        )}
      </div>
    );
  };

  const saveOrder = () => {
    const orderExists = typeof id !== 'undefined';
    addRemovedLineItems(order.deletedOrderLines);
    dispatch(orderActions.saveOrder(order, {}, notificationCont)).then((res) => {
      setReadyToSave(false);
      setReadyToAdd(true);
      setReadyToSubmit(res.orderLines.length > 0);

      if (!orderExists) {
        history.push(`/order/${res.orderNumber}`);
      }

      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: orderExists
            ? t('msg_codes_orderDetailsAdmin_orderUpdated_toast')
            : t('msg_codes_orderDetailsAdmin_orderCreated_toast'),
        },
      });

      return true;
    });
  };

  const submitOrder = () => {
    dispatch(orderActions.submitOrder(order.orderNumber)).then(() => {
      notificationCont.dispatch({
        type: 'add',
        payload: {
          status: 'success',
          autoHide,
          timeout,
          message: t('msg_codes_orderDetailsAdmin_orderSubmitted_toast'),
        },
      });
      history.push('/orders');
    });
  };

  const downloadOrderSummary = () => {
    orderActions.downloadOrder(order.orderNumber)(dispatch);
  };

  const checkIfReadyToSave = () => {
    const hasLines = order.orderLines && order.orderLines
      .some(orderline => !Object.prototype.hasOwnProperty.call(orderline, 'deletedOrderLineId'));

    if (initialLoad && !order.linesAdded) {
      setReadyToSave(false);
      setReadyToSubmit(hasLines && order.orderStatus !== 'PENDING_DRAFT_AMENDMENT');
      setReadyToAdd(order.orderStatus === 'DRAFT_ORDER' || order.orderStatus === 'PENDING_DRAFT_AMENDMENT');

      return false;
    }

    // Check all required order info fields have values
    const requiredFields = [
      'orderReference',
      'region',
      'additionalRedemptionCheckRequired',
      'cardManufacturePriceCurrency',
    ];

    // Require cardManufacturePriceCurrency field only if order has
    //  totalManufactureFreightCostAndAdditionalCosts (for existing order) or freightCost
    //  and freightCostTaxPercentage (for new order).
    if (totalManufactureFreightCostAndAdditionalCosts > 0
      || (Number(freightCost) > 0 && Number(freightCostTaxPercentage) > 0)
    ) {
      requiredFields.push('cardManufacturePriceCurrency');
    }

    const allOrderInfoRequiredFieldsHaveValue = requiredFields.filter((field) => {
      const val = order[field];
      return typeof val !== 'undefined' && val !== '' && val !== 'select' && val !== null;
    }).length === requiredFields.length;

    // Check if input length of order name, comments, or po is greater than 150
    const invalidLength = ['orderComments', 'poNumber', 'orderReference'].some((field) => {
      return typeof order[field] === 'string' && order[field].length > 150;
    });

    // Check if input length of itemReference is greater than 500
    const invalidItemReferenceLength = order.orderLines?.some((line) => {
      return typeof line.itemReference === 'string' && line.itemReference.length > 500;
    });

    // Check percentage bigger than 100
    const invalidPercentage = order.orderLines?.some((line) => {
      return ['tax'].some((field) => {
        const val = line[field];
        return typeof val === 'string' && val.length > 0 && Number(val) > 100;
      });
    });

    // Check quantity bigger than 900000
    const invalidQuantity = order.orderLines?.some((line) => {
      const val = line.quantity;
      return typeof val === 'string' && val.length > 0 && (Number(val) > 900000 || Number(val) < 1);
    });

    // If not all required order info fields have values, the order is not ready to save
    if (!allOrderInfoRequiredFieldsHaveValue
      || invalidLength
      || invalidItemReferenceLength
      || invalidPercentage
      || invalidQuantity) {
      setReadyToSave(false);

      return true;
    }

    // If existing order, check all fields for changes
    if (!order.newOrder) {
      // If partnerSftp does not have a value, set to ' ' to avoid getting caught in null checks.
      initialOrder = {
        ...initialOrder,
        partnerSftpId: typeof initialOrder.partnerSftpId === 'undefined' || initialOrder.partnerSftpId === null
          ? ' '
          : initialOrder.partnerSftpId,
      };
      const orderFields = Object.keys(order).filter(field => field !== 'orderLines');
      const orderLineFields = order.orderLines.length > 0 ? Object.keys(order.orderLines[0]) : null;

      // order info fields
      const orderInfoFieldsChanged = orderFields.some((field) => {
        const val = order[field];

        // Null checks
        if (
          hasOrderValueChanged(val, field, initialOrder[field])
        ) {
          return false;
        }

        // For these specific fields, they might have different number of decimal places between
        //  their initial and inputted values. Remove any trailing zeroes and compare.
        const freightFields = ['freightCost', 'freightCostTaxPercentage'];
        if (freightFields.includes(field)) {
          return removeTrailingZeros(val) !== removeTrailingZeros(initialOrder[field]);
        }
        if (field === 'partnerSftpId') {
          return initialOrder[field] !== val;
        }
        return initialOrder[field] !== null && val.toString() !== initialOrder[field].toString();
      });
      // line item fields
      const orderLineFieldsChanged = {
        // Check if any line item has changed
        [true]: () => checkIfLineFieldsChanged(order, initialOrder, orderLineFields),
        // Order has no lines
        [!hasLines]: () => false,
        // Order line was either added or removed
        [typeof initialOrder.orderLines !== 'undefined'
          && initialOrder.orderLines.length !== order.orderLines.length]: () => true,
      }.true();

      // Check if any required order lines are blank (quantity input & branded selection for money).
      const areRequiredLineFieldsBlank = order.orderLines.some((line) => {
        const {
          quantity,
          branded,
          _embedded,
          voucherCatalog,
        } = line;
        const catalog = _embedded?.voucherCatalog || voucherCatalog;
        return (
          //  Check if voucher quantity is blank
          typeof quantity === 'string' && quantity === ''
        )
        || (
          // Check if money voucher's branded is blank, undefined, or null
          catalog.type === MONEY.code && isEmpty(branded)
        );
      });
      // Check to see if there is a valid freightCostTaxPercentage
      const isValidFreightCostTaxPercentage = Number(freightCostTaxPercentage).toFixed(2) <= 100
        && Number(freightCostTaxPercentage).toFixed(2) >= 0;

      // Check if any order lines have invalid customId.
      const isCustomIdInvalid = order.orderLines.some((line) => {
        const { customId } = line;
        return (typeof customId === 'string' && customId.length > 20) || !alphaNumeric.test(customId);
      });

      // Ready to save if any fields have been changed and there are no blank voucher quantities.
      const isReadyToSave = (orderInfoFieldsChanged || orderLineFieldsChanged
        || order.linesRemoved) && isValidFreightCostTaxPercentage
        ? (!areRequiredLineFieldsBlank && !isCustomIdInvalid)
        : false;
      setReadyToDownload(!(orderInfoFieldsChanged || orderLineFieldsChanged));
      setReadyToSave(isReadyToSave);
      setReadyToSubmit(
        hasLines && !isReadyToSave && !areRequiredLineFieldsBlank && !isCustomIdInvalid,
      );

      return true;
    }

    // If new order, check order line item required fields have values (if there are lines)
    const requiredLineFields = ['quantity', 'branded'];
    const orderLineRequiredFieldsChanged = !hasLines
      ? false
      : order.orderLines.some((line) => {
        const hasChangedLineFields = requiredLineFields.filter((field) => {
          const val = line[field];

          if (
            (field === 'cardManufacturePrice' || field === 'tax')
            && !order.additionalRedemptionCheckRequired
          ) {
            return true;
          }

          return val !== '' && (field === 'tax' || field === 'cardManufacturePrice')
            ? true
            : Number(val) >= 0.0001 && val !== null;
        });

        return hasChangedLineFields.length === requiredLineFields.length;
      });

    setReadyToSave(allOrderInfoRequiredFieldsHaveValue || orderLineRequiredFieldsChanged);
    setReadyToSubmit(false);
    setReadyToAdd(false);

    return true;
  };

  useEffect(() => {
    checkIfReadyToSave(order);
  }, [order]);
  // Cancel alert
  const handleCancel = useCallback(async () => {
    const { orderNumber } = order;
    const orderExists = typeof orderNumber !== 'undefined';

    dispatch(
      trackingActions.fireEvent(
        'order-click',
        'click',
        'empty',
        'cancel',
        'order-submission',
        '1',
        'button',
      ),
    );

    setCancelling(true);

    if (!orderExists) {
      history.push('/orders');
      notify.success(t('msg_codes_orderCanceled'));
    }

    try {
      await orderActions.cancelOrder(
        orderNumber,
      )(dispatch);
      notify.success(t('msg_codes_orderCanceled'));
      history.push('/orders');
    } catch (error) {
      notify.error(t('msg_codes_codes_tooltip_somethingWentWrong'));
    }

    setCancelling(false);
  }, [order]);


  const alertPropsCancel = {
    title: t('msg_codes_orderList_warning'),
    status: STATUS.WARNING,
    content: t('msg_codes_modal_ordersCanceled_body'),
    primaryButtonLabel: t('msg_codes_common_yes'),
    secondaryButtonLabel: t('msg_codes_cta_cancel'),
    primaryAction: () => {
      handleCancel();
    },
  };

  const renderCancelAlert = () => {
    return (
      alertContext.dispatch({
        type: ALERT_ACTIONS.SHOW,
        payload: {
          component: (
            <Alert {...alertPropsCancel} />
          ),
        },
      })
    );
  };


  const renderApprovalButton = () => {
    const isDisabled = disableActionButtons || waiting;

    const tooltipMessage = {
      // Default tooltip
      [true]: () => t(
        'msg_codes_tooltip_levelApprover',
        { number: userApprovalLevel },
      ),
      // When user's approval level is lower than order's next approval level
      [userBelowNextLevel]: () => t(
        'msg_codes_tooltip_waitingApproval',
        { number: maximumRequiredApprovalLevel },
      ),
      // When user's approval level is above order's current approval level
      [userApprovalLevel > currentApprovalLevel]: () => t(
        'msg_codes_tooltip_orderApprove',
        { number: currentApprovalLevel },
      ),
      // When user's approval level is above order's current approval level
      [isWaitingForInitialApproval && !userCanInitialApprove]: () => t(
        'msg_codes_tooltip_waitingApproval',
        { number: 0 },
      ),
      // When order is ready for final approval
      [orderIsInFinalApproval]: () => t(
        'msg_codes_tooltip_finalApprove',
      ),
    }.true();

    const buttonText = {
      [true]: () => t('msg_codes_cta_order_approve'),
      [userBelowNextLevel]: () => t(
        'msg_codes_cta_pendingApproval',
        { number: maximumRequiredApprovalLevel },
      ),
      [isWaitingForInitialApproval && !userCanInitialApprove]: () => t(
        'msg_codes_cta_pendingApproval',
        { number: 0 },
      ),
      [orderIsInFinalApproval]: () => t('msg_codes_cta_finalApprove'),
    }.true();

    return (
      <Tooltip
        content={tooltipMessage}
        strategy="fixed"
        anchor={pageRef}
        shortCopy
      >
        <Button
          id="approve-button"
          icon="ico-checkmark-circle"
          disabled={isDisabled}
          loading={approving}
          onClick={handleApprove}
        >
          {buttonText}
        </Button>
      </Tooltip>
    );
  };

  return (
    <div data-testid="order-detail-nav" className="order-detail-nav">
      <Container>
        <Layout>
          <Flex>
            <div className="order-detail-navbar">
              <div className="order-number">
                {typeof order.orderNumber === 'undefined'
                  ? ''
                  : (
                    <h3 data-testid="order-number">
                      {t('msg_codes_ordernum')} : {order.orderNumber}
                    </h3>
                  )
                }
              </div>
              <div className="buttons">
                <span
                  className="download"
                  data-testid="download-button"
                >
                  {isApproved && (
                    <Button
                      id="save-button"
                      onClick={downloadOrderSummary}
                      disabled={!readyToDownload || loading}
                      loading={loading}
                      secondary
                    >
                      {t('msg_codes_download_pdf')}
                    </Button>
                  )}
                </span>
                <span
                  className="cancel"
                  data-testid="cancel-button"
                >
                  {showCancel && (
                    <Button
                      id="cancel-button"
                      disabled={cancelling}
                      onClick={renderCancelAlert}
                    >
                      {t('msg_codes_newOrder_cancelOrder')}
                    </Button>
                  )}
                </span>
                <span
                  className="save"
                  data-testid="save-button"
                >
                  {showSave && (
                    <Button
                      id="save-button"
                      onClick={saveOrder}
                      disabled={!readyToSave || savingOrder}
                      loading={savingOrder}
                      primary
                    >
                      {savebuttonLabel}
                    </Button>
                  )}
                </span>
                <span
                  className="approve"
                >
                  {showApprove && (
                    <div
                      className="buttons approve"
                      data-testid="approve-buttons"
                    >
                      {!orderIsInFinalApproval && renderActionMessage(
                        'amend',
                        <Button
                          id="request-amendment-button"
                          icon="chat"
                          disabled={disableActionButtons || waiting}
                          loading={requestingAmendment}
                          onClick={() => openMessageBoard('amend')}
                        >
                          {t('msg_codes_orderDetailsAdmin_reqAmendment_cta')}
                        </Button>,
                      )}
                      {!orderIsInFinalApproval && renderActionMessage(
                        'reject',
                        <Button
                          id="reject-button"
                          icon="ico-remove_cancel"
                          disabled={disableActionButtons || waiting}
                          loading={rejecting}
                          onClick={() => openMessageBoard('reject')}
                        >
                          {t('msg_codes_cta_common_reject')}
                        </Button>,
                      )}
                      {renderApprovalButton()}
                    </div>
                  )}
                </span>
                <span
                  className="submit"
                  data-testid="submit-button"
                >
                  {showSubmit && (
                    <Button
                      id="submit-button"
                      onClick={submitOrder}
                      disabled={!readyToSubmit || submittingOrder || savingOrder}
                      loading={submittingOrder}
                      primary
                    >
                      {t('msg_codes_common_submit')}
                    </Button>
                  )}
                </span>
              </div>
            </div>
          </Flex>
        </Layout>
      </Container>
    </div>
  );
};

Nav.propTypes = {
  order: PropTypes.object,
  setReadyToAdd: PropTypes.func,
  initialLoad: PropTypes.bool,
  triggerInitialOrder: PropTypes.bool,
  setInitialLoad: PropTypes.func,
  pageRef: PropTypes.object,
};

Nav.defaultProps = {
  order: {},
  setReadyToAdd: () => {},
  initialLoad: false,
  triggerInitialOrder: false,
  setInitialLoad: () => {},
  pageRef: {},
};

export default Nav;
