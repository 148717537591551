import roleKeys from '../roleKeys';

export default {
  env: 'e1-np',
  adobe: '//assets.adobedtm.com/3dc13bcabc29/a4d9cd7252f2/launch-e12153e205bb-development.min.js',
  [roleKeys.thirdParty.viewCatalog]: true,
  [roleKeys.codeProducts.viewList]: true,
  [roleKeys.codeProduct.viewDetail]: true,
  [roleKeys.partner.viewThirdPartyAgreements]: true,
  [roleKeys.voucherTypes.viewList]: true,
  [roleKeys.voucherBatches.viewList]: true,
  [roleKeys.voucherTypes.viewDetail]: true,
  [roleKeys.voucherBatches.viewDetail]: true,
  i18nDebug: false,
  feesUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Fees',
  helpUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Welcome',
  downloadHelpUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Download',
  startEndDateUrl: 'https://learn.sit.pt.playstation.net/csh?context=Start_End_Date',
  contentPipelineUrl: 'https://scp.cs.pt.playstation.net',
};
