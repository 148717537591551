import roleKeys from './roleKeys';
import getFeature from './getFeature';
import status from '../../constants/order.constants';

const canCancelOrder = (submissionState) => {
  return submissionState === 'DRAFT'
  || submissionState === 'DRAFT_ORDER'
  || submissionState === 'PENDING_DRAFT_AMENDMENT'
  || submissionState === 'SUBMITTED_PENDING_APPROVAL';
};

export const SUPER_ADMIN = {
  order: {
    viewList: true,
    viewAgreementType: true,
    viewLineErrors: true,
    viewThirdPartyLineErrors: true,
    searchList: true,
    viewSearchListPartnerName: true,
    viewDetail: true,
    create: true,
    cancel: true,
    editReference: true,
    editContractingPartner: true,
    editContractingSIERegion: true,
    editSftpId: (orderStatus) => {
      const allowedStatuses = [
        status.DRAFT_ORDER,
        status.PENDING_DRAFT_AMENDMENT,
        status.SUBMITTED_PENDING_APPROVAL,
      ];
      return allowedStatuses.includes(orderStatus);
    },
    editAdditionalRedemptionCheck: true,
    editManufacturingCostCurrency: true,
    editFreightCost: true,
    editFreightCostTax: true,
    addPurchaseOrderNumber: true,
    editPurchaseOrderNumber: true,
    viewPurchaseOrderNumber: true,
    editComments: true,
    addApprovalComments: true,
    viewApprovalComments: true,
    approvalActions: true,
    hasOrderRegions: () => 'all',
    canSeeSaveButton: true,
    canInitialApprove: true,
    editOrderFee: true,
    editAdditionalCost: true,
  },
  permittedUsesOrder: {
    viewDetail: true,
    canSave: true,
    canApprove: true,
    downloadPO: true,
    editApplyFeeWaiver: true,
    viewComment: true,
    editUseCount: true,
  },
  orderContent: {
    addLineItem: true,
    removeLineItem: true,
    cancelLineItem: () => true,
    activateLineItem: true,
    downloadLineItem: true,
    retryFulfillment: true,
    retryActivation: true,
    viewLineErrors: true,
    editManufactureCost: true,
    editTax: true,
    editItemReference: true,
    editQuantity: true,
    editItem: true,
    viewStartDate: true,
    viewEndDate: true,
    editBranded: true,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: true,
    viewShareWithPartner: true,
    viewRemoveTooltip: false,
    uploadAttachment: true,
    canDownload: () => true,
  },
  freeAllocationOrderContent: {
    viewDetail: true,
    activateLineItem: true,
    downloadLineItem: true,
    cancelLineItem: true,
    retryLineItem: true,
    viewFulfillmentError: true,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: true,
    retryLineItem: true,
    cancelLineItem: true,
    editLineDateTime: true,
    canSwapCodeProduct: true,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: true,
    search: true,
  },
  adminCatalog: {
    searchCatalog: true,
    viewDetail: true,
  },
  thirdParty: {
    viewCatalog: true,
    download: true,
    canSeeCart: true,
    canSeeOrderHistory: true,
    canSeeOrderHistorySuccess: true,
    generate: true,
    redownload: true,
    viewErrorMessage: true,
    retry: true,
    searchCatalog: true,
    canEditUseCount: true,
  },
  partner: {
    viewList: true,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: true,
    viewDetail: true,
    create: true,
    edit: true,
    canSetSuperAdmin: true,
    canSeePgpKeys: true,
    canSetupPgpKeys: true,
    canAddPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canRemovePgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canDownloadPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canSeeNoPgpKeyModalMessage: false,
  },
  agreement: {
    viewList: true,
    viewDetail: true,
    create: true,
    createThirdParty: false,
    edit: true,
    showAgreementVersionDetails: true,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: true,
    viewDetail: true,
    viewReadyOnlyDetail: true,
    create: true,
    edit: true,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: true,
    viewDetail: true,
    create: true,
    edit: true,
  },
  termsOfService: {
    canView: false,
    viewCanceled: false,
  },
  codeProduct: {
    createDetail: true,
    viewDetail: true,
    editDetail: true,
    viewEndDate: true,
  },
  codeProducts: {
    viewList: true,
    create: true,
  },
  maintainanceDashboard: {
    viewPage: true,
  },
  confirmation: {
    viewPage: true,
  },
};

export const ADMIN = {
  order: {
    viewList: true,
    viewAgreementType: true,
    viewLineErrors: true,
    viewThirdPartyLineErrors: true,
    searchList: true,
    viewSearchListPartnerName: true,
    viewDetail: true,
    create: false,
    cancel: true,
    editReference: true,
    editContractingPartner: true,
    editContractingSIERegion: true,
    editSftpId: (orderStatus) => {
      const allowedStatuses = [
        status.DRAFT_ORDER,
        status.PENDING_DRAFT_AMENDMENT,
        status.SUBMITTED_PENDING_APPROVAL,
      ];
      return allowedStatuses.includes(orderStatus);
    },
    editAdditionalRedemptionCheck: true,
    editManufacturingCostCurrency: true,
    editFreightCost: true,
    editFreightCostTax: true,
    addPurchaseOrderNumber: true,
    editPurchaseOrderNumber: true,
    viewPurchaseOrderNumber: true,
    editComments: true,
    addApprovalComments: false,
    viewApprovalComments: true,
    approvalActions: false,
    hasOrderRegions: false,
    canSeeSaveButton: true,
    canInitialApprove: false,
    editOrderFee: false,
    editAdditionalCost: false,
  },
  permittedUsesOrder: {
    viewDetail: true,
    canSave: true,
    canApprove: false,
    downloadPO: true,
    editApplyFeeWaiver: false,
    viewComment: true,
    editUseCount: false,
  },
  orderContent: {
    addLineItem: false,
    removeLineItem: true,
    cancelLineItem: () => true,
    activateLineItem: true,
    downloadLineItem: true,
    retryFulfillment: true,
    retryActivation: true,
    viewLineErrors: true,
    editManufactureCost: true,
    editTax: true,
    editItemReference: true,
    editQuantity: true,
    editItem: true,
    viewStartDate: true,
    viewEndDate: true,
    editBranded: true,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: true,
    viewShareWithPartner: false,
    viewRemoveTooltip: true,
    uploadAttachment: false,
    canDownload: () => true,
  },
  freeAllocationOrderContent: {
    viewDetail: true,
    activateLineItem: true,
    downloadLineItem: true,
    cancelLineItem: true,
    retryLineItem: true,
    viewFulfillmentError: true,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: true,
    retryLineItem: true,
    cancelLineItem: true,
    editLineDateTime: true,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: true,
    search: true,
  },
  adminCatalog: {
    searchCatalog: true,
    viewDetail: true,
  },
  thirdParty: {
    viewCatalog: false,
    download: false,
    canSeeCart: false,
    canSeeOrderHistory: false,
    canSeeOrderHistorySuccess: false,
    generate: false,
    redownload: false,
    viewErrorMessage: false,
    retry: false,
    searchCatalog: false,
    canEditUseCount: false,
  },
  partner: {
    viewList: true,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: true,
    viewDetail: true,
    create: true,
    edit: true,
    canSetSuperAdmin: false,
    canSeePgpKeys: true,
    canSetupPgpKeys: true,
    canAddPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canRemovePgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canDownloadPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canSeeNoPgpKeyModalMessage: false,
  },
  agreement: {
    viewList: true,
    viewDetail: true,
    create: true,
    createThirdParty: false,
    edit: true,
    showAgreementVersionDetails: true,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: true,
    viewDetail: true,
    viewReadyOnlyDetail: true,
    create: true,
    edit: true,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: true,
    viewDetail: true,
    create: true,
    edit: true,
  },
  termsOfService: {
    canView: false,
    viewCanceled: false,
  },
  codeProduct: {
    createDetail: true,
    viewDetail: true,
    editDetail: true,
    viewEndDate: true,
  },
  codeProducts: {
    viewList: true,
    create: true,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};

export const AGENCY_ADMIN = {
  order: {
    viewList: true,
    viewAgreementType: true,
    viewLineErrors: true,
    viewThirdPartyLineErrors: false,
    searchList: true,
    viewSearchListPartnerName: true,
    viewDetail: true,
    create: false,
    cancel: true,
    editReference: true,
    editContractingPartner: true,
    editContractingSIERegion: true,
    editSftpId: (orderStatus) => {
      const allowedStatuses = [
        status.DRAFT_ORDER,
        status.PENDING_DRAFT_AMENDMENT,
        status.SUBMITTED_PENDING_APPROVAL,
      ];
      return allowedStatuses.includes(orderStatus);
    },
    editAdditionalRedemptionCheck: true,
    editManufacturingCostCurrency: true,
    editFreightCost: true,
    editFreightCostTax: true,
    addPurchaseOrderNumber: true,
    editPurchaseOrderNumber: true,
    viewPurchaseOrderNumber: true,
    editComments: true,
    addApprovalComments: false,
    viewApprovalComments: true,
    approvalActions: false,
    hasOrderRegions: false,
    canSeeSaveButton: true,
    canInitialApprove: false,
    editOrderFee: false,
    editAdditionalCost: true,
  },
  permittedUsesOrder: {
    viewDetail: true,
    canSave: true,
    canApprove: false,
    downloadPO: true,
    editApplyFeeWaiver: false,
    viewComment: true,
    editUseCount: false,
  },
  orderContent: {
    addLineItem: false,
    removeLineItem: true,
    cancelLineItem: () => true,
    activateLineItem: true,
    downloadLineItem: true,
    retryFulfillment: true,
    retryActivation: true,
    viewLineErrors: true,
    editManufactureCost: true,
    editTax: true,
    editItemReference: true,
    editQuantity: true,
    editItem: true,
    viewStartDate: true,
    viewEndDate: true,
    editBranded: true,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: true,
    viewShareWithPartner: true,
    viewRemoveTooltip: false,
    uploadAttachment: true,
    canDownload: () => true,
  },
  freeAllocationOrderContent: {
    viewDetail: true,
    activateLineItem: true,
    downloadLineItem: true,
    cancelLineItem: true,
    retryLineItem: true,
    viewFulfillmentError: true,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: true,
    retryLineItem: true,
    cancelLineItem: true,
    editLineDateTime: true,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: true,
    search: true,
  },
  adminCatalog: {
    searchCatalog: true,
    viewDetail: true,
  },
  thirdParty: {
    viewCatalog: false,
    download: false,
    canSeeCart: false,
    canSeeOrderHistory: false,
    canSeeOrderHistorySuccess: false,
    generate: false,
    redownload: false,
    viewErrorMessage: false,
    retry: false,
    searchCatalog: false,
    canEditUseCount: false,
  },
  partner: {
    viewList: true,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: true,
    viewDetail: true,
    create: true,
    edit: true,
    canSetSuperAdmin: false,
    canSeePgpKeys: true,
    canSetupPgpKeys: true,
    canAddPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canRemovePgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canDownloadPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
  },
  agreement: {
    viewList: true,
    viewDetail: true,
    create: true,
    createThirdParty: false,
    edit: true,
    showAgreementVersionDetails: true,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: false,
    viewDetail: false,
    viewReadyOnlyDetail: false,
    create: false,
    edit: false,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: false,
    viewDetail: false,
    create: false,
    edit: false,
  },
  termsOfService: {
    canView: false,
    viewCanceled: false,
  },
  codeProduct: {
    createDetail: false,
    viewDetail: true,
    editDetail: false,
    viewEndDate: true,
  },
  codeProducts: {
    viewList: true,
    create: false,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};

export const APPROVER = {
  order: {
    viewList: true,
    viewAgreementType: true,
    viewLineErrors: false,
    viewThirdPartyLineErrors: false,
    searchList: true,
    viewSearchListPartnerName: true,
    viewDetail: true,
    create: false,
    cancel: false,
    editReference: false,
    editContractingPartner: false,
    editContractingSIERegion: false,
    editSftpId: false,
    editAdditionalRedemptionCheck: false,
    editManufacturingCostCurrency: false,
    editFreightCost: false,
    editFreightCostTax: false,
    addPurchaseOrderNumber: true,
    editPurchaseOrderNumber: true,
    viewPurchaseOrderNumber: true,
    editComments: false,
    addApprovalComments: true,
    viewApprovalComments: true,
    approvalActions: true,
    hasOrderRegions: false,
    canSeeSaveButton: true,
    canInitialApprove: userApprovalLevel => userApprovalLevel === 0,
    editOrderFee: true,
    editAdditionalCost: false,
  },
  permittedUsesOrder: {
    viewDetail: true,
    canSave: true,
    canApprove: true,
    downloadPO: true,
    editApplyFeeWaiver: true,
    viewComment: true,
    editUseCount: true,
  },
  orderContent: {
    addLineItem: false,
    removeLineItem: false,
    cancelLineItem: () => false,
    activateLineItem: false,
    downloadLineItem: false,
    retryFulfillment: false,
    retryActivation: false,
    viewLineErrors: false,
    editManufactureCost: false,
    editTax: false,
    editItemReference: false,
    editQuantity: false,
    editItem: false,
    viewStartDate: true,
    viewEndDate: true,
    editBranded: false,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: true,
    viewShareWithPartner: true,
    viewRemoveTooltip: false,
    uploadAttachment: true,
    canDownload: () => true,
  },
  freeAllocationOrderContent: {
    viewDetail: true,
    activateLineItem: false,
    downloadLineItem: false,
    cancelLineItem: false,
    retryLineItem: false,
    viewFulfillmentError: false,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: false,
    retryLineItem: false,
    cancelLineItem: false,
    editLineDateTime: true,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: true,
    search: true,
  },
  adminCatalog: {
    searchCatalog: true,
    viewDetail: true,
  },
  thirdParty: {
    viewCatalog: false,
    download: false,
    canSeeCart: false,
    canSeeOrderHistory: false,
    canSeeOrderHistorySuccess: false,
    generate: false,
    redownload: false,
    viewErrorMessage: false,
    retry: false,
    searchCatalog: false,
    canEditUseCount: true,
  },
  partner: {
    viewList: true,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: true,
    viewDetail: true,
    create: false,
    edit: false,
    canSetSuperAdmin: false,
    canSeePgpKeys: false,
    canAddPgpKeys: false,
    canRemovePgpKeys: false,
    canDownloadPgpKeys: false,
    canSetupPgpKeys: false,
    canSeeNoPgpKeyModalMessage: false,
  },
  agreement: {
    viewList: true,
    viewDetail: true,
    create: false,
    createThirdParty: false,
    edit: false,
    showAgreementVersionDetails: true,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: true,
    viewDetail: true,
    viewReadyOnlyDetail: false,
    create: false,
    edit: false,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: true,
    viewDetail: true,
    create: false,
    edit: false,
  },
  termsOfService: {
    canView: false,
    viewCanceled: false,
  },
  codeProduct: {
    createDetail: false,
    viewDetail: true,
    editDetail: false,
    viewEndDate: false,
  },
  codeProducts: {
    viewList: true,
    create: false,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};

export const REVIEWER = {
  order: {
    viewList: true,
    viewAgreementType: true,
    viewLineErrors: false,
    viewThirdPartyLineErrors: false,
    searchList: true,
    viewSearchListPartnerName: true,
    viewDetail: true,
    create: false,
    cancel: false,
    editReference: false,
    editContractingPartner: false,
    editContractingSIERegion: false,
    editSftpId: false,
    editAdditionalRedemptionCheck: false,
    editManufacturingCostCurrency: false,
    editFreightCost: false,
    editFreightCostTax: false,
    addPurchaseOrderNumber: false,
    editPurchaseOrderNumber: false,
    viewPurchaseOrderNumber: true,
    editComments: false,
    addApprovalComments: false,
    viewApprovalComments: true,
    approvalActions: false,
    hasOrderRegions: false,
    canSeeSaveButton: false,
    canInitialApprove: false,
    editOrderFee: false,
    editAdditionalCost: false,
  },
  permittedUsesOrder: {
    viewDetail: true,
    canSave: false,
    canApprove: false,
    downloadPO: true,
    editApplyFeeWaiver: false,
    viewComment: true,
    editUseCount: false,
  },
  orderContent: {
    addLineItem: false,
    removeLineItem: false,
    cancelLineItem: () => false,
    activateLineItem: false,
    downloadLineItem: false,
    retryFulfillment: false,
    retryActivation: false,
    viewLineErrors: false,
    editManufactureCost: false,
    editTax: false,
    editItemReference: false,
    editQuantity: false,
    editItem: false,
    viewStartDate: true,
    viewEndDate: true,
    editBranded: false,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: true,
    viewShareWithPartner: true,
    viewRemoveTooltip: false,
    uploadAttachment: false,
    canDownload: () => true,
  },
  freeAllocationOrderContent: {
    viewDetail: true,
    activateLineItem: false,
    downloadLineItem: false,
    cancelLineItem: false,
    retryLineItem: false,
    viewFulfillmentError: false,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: false,
    retryLineItem: false,
    cancelLineItem: false,
    editLineDateTime: false,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: true,
    search: true,
  },
  adminCatalog: {
    searchCatalog: true,
    viewDetail: true,
  },
  thirdParty: {
    viewCatalog: false,
    download: false,
    canSeeCart: false,
    canSeeOrderHistory: false,
    canSeeOrderHistorySuccess: false,
    generate: false,
    redownload: false,
    viewErrorMessage: false,
    retry: false,
    searchCatalog: false,
    canEditUseCount: false,
  },
  partner: {
    viewList: true,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: true,
    viewDetail: true,
    create: false,
    edit: false,
    canSetSuperAdmin: false,
    canSeePgpKeys: false,
    canAddPgpKeys: false,
    canRemovePgpKeys: false,
    canDownloadPgpKeys: false,
    canSetupPgpKeys: false,
    canSeeNoPgpKeyModalMessage: false,
  },
  agreement: {
    viewList: true,
    viewDetail: true,
    create: false,
    createThirdParty: false,
    edit: false,
    showAgreementVersionDetails: true,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: true,
    viewDetail: true,
    viewReadyOnlyDetail: false,
    create: false,
    edit: false,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: true,
    viewDetail: true,
    create: false,
    edit: false,
  },
  termsOfService: {
    canView: false,
    viewCanceled: false,
  },
  codeProduct: {
    createDetail: false,
    viewDetail: true,
    editDetail: false,
    viewEndDate: false,
  },
  codeProducts: {
    viewList: true,
    create: false,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};

export const INTEGRATOR_PARTNER = {
  order: {
    viewList: true,
    viewAgreementType: false,
    viewLineErrors: false,
    viewThirdPartyLineErrors: false,
    searchList: true,
    viewSearchListPartnerName: false,
    viewDetail: true,
    create: true,
    cancel: canCancelOrder,
    editReference: true,
    editContractingPartner: true,
    editContractingSIERegion: true,
    editSftpId: (orderStatus) => {
      const allowedStatuses = [
        status.DRAFT_ORDER,
        status.PENDING_DRAFT_AMENDMENT,
        status.SUBMITTED_PENDING_APPROVAL,
      ];
      return allowedStatuses.includes(orderStatus);
    },
    editAdditionalRedemptionCheck: true,
    editManufacturingCostCurrency: true,
    editFreightCost: true,
    editFreightCostTax: true,
    addPurchaseOrderNumber: true,
    editPurchaseOrderNumber: true,
    viewPurchaseOrderNumber: true,
    editComments: true,
    addApprovalComments: false,
    viewApprovalComments: true,
    approvalActions: false,
    hasOrderRegions: () => 'some',
    canSeeSaveButton: true,
    canInitialApprove: false,
    editOrderFee: false,
    editAdditionalCost: true,
  },
  permittedUsesOrder: {
    viewDetail: false,
    canSave: false,
    canApprove: false,
    downloadPO: false,
    editApplyFeeWaiver: false,
    viewComment: false,
    editUseCount: false,
  },
  orderContent: {
    addLineItem: true,
    removeLineItem: true,
    cancelLineItem: line => line.activationStatus !== 'Activated'
      && line.deliveryStatus !== 'Delivered',
    activateLineItem: true,
    downloadLineItem: true,
    retryFulfillment: true,
    retryActivation: true,
    viewLineErrors: true,
    editManufactureCost: true,
    editTax: true,
    editItemReference: true,
    editQuantity: true,
    editItem: false,
    viewStartDate: false,
    viewEndDate: false,
    editBranded: true,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: false,
    viewShareWithPartner: false,
    viewRemoveTooltip: (orderStatus, uploadedByPartner) => {
      const editableStatuses = [status.PENDING_DRAFT_AMENDMENT, status.DRAFT_ORDER];
      return !uploadedByPartner || editableStatuses.indexOf(orderStatus) === -1;
    },
    uploadAttachment: (orderStatus) => {
      return orderStatus === status.PENDING_DRAFT_AMENDMENT
        || orderStatus === status.DRAFT_ORDER;
    },
    canDownload: shareWithPartner => shareWithPartner,
  },
  freeAllocationOrderContent: {
    viewDetail: false,
    activateLineItem: false,
    downloadLineItem: false,
    cancelLineItem: false,
    retryLineItem: false,
    viewFulfillmentError: false,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: false,
    retryLineItem: false,
    cancelLineItem: false,
    editLineDateTime: false,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: true,
    viewAllProducts: false,
    search: true,
  },
  adminCatalog: {
    searchCatalog: false,
    viewDetail: false,
  },
  thirdParty: {
    viewCatalog: false,
    download: false,
    canSeeCart: false,
    canSeeOrderHistory: false,
    canSeeOrderHistorySuccess: false,
    generate: false,
    redownload: false,
    viewErrorMessage: false,
    retry: false,
    searchCatalog: false,
    canEditUseCount: false,
  },
  partner: {
    viewList: false,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: true,
    viewThirdPartyPublisherAgreements: false,
  },
  user: {
    view: false,
    viewDetail: true,
    create: false,
    edit: false,
    canSetSuperAdmin: false,
    canSeePgpKeys: true,
    canSetupPgpKeys: true,
    canAddPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canRemovePgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canDownloadPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canSeeNoPgpKeyModalMessage: true,
  },
  agreement: {
    viewList: false,
    viewDetail: true,
    create: false,
    createThirdParty: false,
    edit: false,
    showAgreementVersionDetails: false,
  },
  mainPage: {
    componentName: 'Orders',
  },
  voucherTypes: {
    viewList: false,
    viewDetail: false,
    viewReadyOnlyDetail: false,
    create: false,
    edit: false,
  },
  access: {
    callbackGateway: true,
  },
  voucherBatches: {
    viewList: false,
    viewDetail: false,
    create: false,
    edit: false,
  },
  termsOfService: {
    canView: true,
    viewCanceled: true,
  },
  codeProduct: {
    createDetail: false,
    viewDetail: false,
    editDetail: false,
    viewEndDate: false,
  },
  codeProducts: {
    viewList: false,
    create: false,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};

export const THIRD_PARTY_PARTNER = {
  order: {
    viewList: false,
    viewAgreementType: false,
    viewLineErrors: false,
    viewThirdPartyLineErrors: false,
    searchList: false,
    viewSearchListPartnerName: false,
    viewDetail: false,
    create: false,
    cancel: false,
    editReference: false,
    editContractingPartner: false,
    editContractingSIERegion: false,
    editSftpId: false,
    editAdditionalRedemptionCheck: false,
    editManufacturingCostCurrency: false,
    editFreightCost: false,
    editFreightCostTax: false,
    addPurchaseOrderNumber: false,
    editPurchaseOrderNumber: false,
    viewPurchaseOrderNumber: false,
    editComments: false,
    addApprovalComments: false,
    viewApprovalComments: false,
    approvalActions: false,
    hasOrderRegions: () => 'some',
    canSeeSaveButton: false,
    canInitialApprove: false,
    editOrderFee: false,
    editAdditionalCost: false,
  },
  permittedUsesOrder: {
    viewDetail: false,
    canSave: false,
    canApprove: false,
    downloadPO: false,
    editApplyFeeWaiver: false,
    viewComment: false,
    editUseCount: false,
  },
  orderContent: {
    addLineItem: false,
    removeLineItem: false,
    cancelLineItem: false,
    activateLineItem: false,
    downloadLineItem: false,
    retryFulfillment: false,
    retryActivation: false,
    viewLineErrors: false,
    editManufactureCost: false,
    editTax: false,
    editItemReference: false,
    editQuantity: false,
    editItem: false,
    viewStartDate: false,
    viewEndDate: false,
    editBranded: false,
  },
  orderDocuments: {
    viewList: true,
    viewFileHash: false,
    viewShareWithPartner: false,
    viewRemoveTooltip: true,
    uploadAttachment: false,
    canDownload: () => false,
  },
  freeAllocationOrderContent: {
    viewDetail: false,
    activateLineItem: false,
    downloadLineItem: true,
    cancelLineItem: false,
    retryLineItem: false,
    viewFulfillmentError: false,
  },
  permittedUsesOrderContent3P: {
    downloadLineItem: false,
    retryLineItem: false,
    cancelLineItem: false,
    editLineDateTime: false,
    canSwapCodeProduct: false,
  },
  orderProductCatalog: {
    viewCatalog: false,
    viewAllProducts: false,
    search: false,
  },
  adminCatalog: {
    searchCatalog: false,
    viewDetail: false,
  },
  thirdParty: {
    viewCatalog: true,
    download: true,
    canSeeCart: true,
    canSeeOrderHistory: true,
    canSeeOrderHistorySuccess: true,
    generate: true,
    redownload: true,
    viewErrorMessage: true,
    retry: true,
    searchCatalog: true,
    canEditUseCount: false,
  },
  partner: {
    viewList: false,
    viewDetail: true,
    create: false,
    editName: false,
    editStatus: false,
    viewThirdPartyAgreements: getFeature(roleKeys.partner.viewThirdPartyAgreements),
    viewThirdPartyPublisherAgreements: true,
  },
  user: {
    view: false,
    viewDetail: true,
    create: false,
    edit: false,
    canSetSuperAdmin: false,
    canSeePgpKeys: true,
    canSetupPgpKeys: true,
    canAddPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canRemovePgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canDownloadPgpKeys: (ownEmail, userEmail) => ownEmail === userEmail,
    canSeeNoPgpKeyModalMessage: true,
  },
  agreement: {
    viewList: false,
    viewDetail: true,
    create: false,
    createThirdParty: false,
    showAgreementVersionDetails: false,
    edit: (agreement) => {
      const allowedStatuses = ['ACTIVE', 'SUSPENDED'];
      return allowedStatuses.includes(agreement);
    },
  },
  mainPage: {
    componentName: getFeature('thirdParty.viewCatalog') ? 'ThirdPartyCatalogPage' : false,
  },
  voucherTypes: {
    viewList: false,
    viewDetail: false,
    viewReadyOnlyDetail: false,
    create: false,
    edit: false,
  },
  access: {
    callbackGateway: false,
  },
  voucherBatches: {
    viewList: false,
    viewDetail: false,
    create: false,
    edit: false,
  },
  termsOfService: {
    canView: true,
    viewCanceled: true,
  },
  codeProduct: {
    createDetail: false,
    viewDetail: false,
    editDetail: false,
    viewEndDate: false,
  },
  codeProducts: {
    viewList: false,
    create: false,
  },
  maintainanceDashboard: {
    viewPage: false,
  },
  confirmation: {
    viewPage: true,
  },
};
