export default [
  {
    id: 'codeProductName',
    value: 'codeProductName',
    stringId: 'msg_codes_voucherProductName',
    sortable: true,
  },
  {
    id: 'codeProductType',
    value: 'codeProductType',
    stringId: 'msg_codes_orderList_codeProductType',
    sortable: true,
  },
  {
    id: 'voucherTypeId',
    value: 'voucherTypeId',
    stringId: 'msg_codes_voucher_type_id',
    sortable: true,
  },
  {
    id: 'applicableAgreementTypes',
    value: 'applicableAgreementTypes',
    stringId: 'msg_codes_agreement_types',
    sortable: false,
  },
  {
    id: 'exclusiveToPartner',
    value: 'exclusiveToPartner',
    stringId: 'msg_codes_exclusive_partner',
    sortable: false,
  },
  {
    id: 'region',
    value: 'region',
    stringId: 'msg_codes_common_region',
    sortable: true,
  },
  {
    id: 'lastUpdated',
    value: 'lastUpdated',
    stringId: 'msg_codes_last_updated',
    sortable: true,
  },
  {
    id: 'status',
    value: 'status',
    stringId: 'msg_codes_agreements_agreementStatus',
    sortable: true,
  },
];
