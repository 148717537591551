import roleKeys from '../roleKeys';

export default {
  env: 'local',
  adobe: '//assets.adobedtm.com/3dc13bcabc29/a4d9cd7252f2/launch-e12153e205bb-development.min.js',
  [roleKeys.thirdParty.viewCatalog]: true,
  i18nDebug: true,
  feesUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Fees',
  helpUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Welcome',
  downloadHelpUrl: 'https://learn.sit.pt.playstation.net/csh?context=3P_Download',
  startEndDateUrl: 'https://learn.sit.pt.playstation.net/csh?context=Start_End_Date',
  contentPipelineUrl: 'https://scp.cs.pt.playstation.net',
  // testing prodline flags
  // mainPageOverride: {
  //   component: 'VoucherTypes',
  //   permission: roleKeys.voucherTypes.viewList,
  // },
  // [roleKeys.thirdParty.viewCatalog]: false,
  // [roleKeys.codeProducts.viewList]: false,
  // [roleKeys.codeProduct.viewDetail]: false,
  // [roleKeys.order.viewDetail]: false,
  // [roleKeys.order.viewList]: false,
  // [roleKeys.agreement.viewDetail]: false,
  // [roleKeys.agreement.viewList]: false,
  // [roleKeys.partner.viewDetail]: false,
  // [roleKeys.partner.viewList]: false,
};
