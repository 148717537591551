import React, {
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import {
  useSelector,
  useDispatch,
  shallowEqual,
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Layout,
  Flex,
  DataTable,
  ActionBar,
  Button,
  TableHeader,
  TableHeaderCell,
  NoResults,
  RowDisplay,
  Pagination,
} from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import SearchHeader from './SearchHeader';
import headerColumns from './TableHeaders';
import RowDisplayLocalization from '../../helpers/RowDisplayLocalization';
import FiltersContainer from '../common/Filters/FiltersContainer';
import { filterCounter } from '../../helpers/filterMethods';
import * as filterOptions from '../../constants/filterOptions.constants';
import * as codeProductsActions from '../../actions/codeProductActions';
import CodeProduct from './CodeProduct';
import hasPermission from '../../utils/accessControl/hasPermission';
import roles from '../../utils/accessControl/roleKeys';
import './CodeProducts.scss';
import { loadPartners } from '../../actions/partnerActions';


const defaultFilters = {
  codeProductType: [],
  applicableAgreementType: [],
  exclusiveToPartner: [],
  region: [],
};

export const initialState = {
  currentSearch: '',
  filters: defaultFilters,
  filtered: false,
  filterCount: 0,
  searched: false,
  currentSort: {
    sortBy: 'lastUpdated',
    sortDir: 'desc',
  },
  currentPageNumber: 0,
  currentPageSize: 50,
};

export const componentActions = {
  updateSearch: 0,
  updateSort: 1,
  updatePageNumber: 2,
  updatePageSize: 3,
  setFilter: 4,
  clearFilters: 5,
};

export function localStateReducer(
  state,
  {
    type = '',
    name,
    value,
    filters = defaultFilters,
    ...payload
  },
) {
  const filteredState = filters && (
    filters.codeProductType.length > 0
    || filters.applicableAgreementType.length > 0
    || filters.exclusiveToPartner.length > 0
    || filters.region.length > 0);

  let newState;

  switch (type) {
    case componentActions.setFilter:
      newState = {
        ...state,
        filters: { ...state.filters, [name]: value },
        filtered: !filteredState,
      };
      return { ...newState, filterCount: filterCounter(newState.filters) };
    case componentActions.clearFilters:
      return {
        ...state,
        filters: defaultFilters,
        filtered: false,
        filterCount: 0,
      };
    case componentActions.updateSearch:
      return {
        ...state,
        currentSearch: payload.currentSearch,
        searched: payload.searched,
        currentPageNumber: 0,
      };
    case componentActions.updateSort:
      return {
        ...state,
        currentSort: payload.currentSort,
      };
    case componentActions.updatePageNumber:
      return {
        ...state,
        currentPageNumber: payload.pageNumber,
      };
    case componentActions.updatePageSize:
      localStorage.setItem(`codeProductsPageSize-${payload.userId}`, payload.pageSize);
      return {
        ...state,
        currentPageSize: payload.pageSize,
        currentPageNumber: 0,
      };

    default:
      return state;
  }
}

export const CodeProductsCatalogPage = ({ ...props }) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { userId } = useSelector(state => state.user);
  const partners = useSelector(state => state.partners);
  let updatedInitialState = initialState;
  if (localStorage.getItem(`codeProductsPageSize-${userId}`) !== null) {
    updatedInitialState = {
      ...updatedInitialState,
      currentPageSize: Number(localStorage.getItem(`codeProductsPageSize-${userId}`)),
    };
  }
  const [localState, localDispatch] = useReducer(localStateReducer, updatedInitialState);
  const loadingCodeProducts = useSelector(state => state.loadingCodeProducts);
  const codeProducts = useSelector(state => state.codeProductsCatalog);
  const codeProductsPage = useSelector(state => state.codeProductsPage.page, shallowEqual);

  const { size, number, totalElements } = codeProductsPage;
  const { filters, filterCount } = localState;

  const canViewCodeProducts = useSelector(
    state => hasPermission(state, roles.codeProducts.viewList),
  );
  const canCreateCodeProduct = useSelector(
    state => hasPermission(state, roles.codeProducts.create),
  );

  const newCodeProduct = () => {
    history.push('/codeproduct');
  };

  useEffect(() => {
    loadPartners(20000)(dispatch);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchParam = queryParams.toString().split('=');
    const term = searchParam[1];

    if (term) {
      localDispatch({
        type: componentActions.updateSearch,
        currentSearch: decodeURIComponent(term.replaceAll('+', ' ')),
        searched: true,
      });
    }
  }, [location]);

  const changeSearch = useCallback((term) => {
    const queryParams = new URLSearchParams();
    if (term) {
      queryParams.set('search', term);
      history.push(`${location.pathname}?${queryParams}`);
    } else {
      history.push(`${location.pathname}`);
    }

    localDispatch({
      type: componentActions.updateSearch,
      currentSearch: term,
      searched: true,
    });
  });

  const getCodeProducts = useCallback(() => {
    const {
      currentSearch,
      currentPageSize,
      currentPageNumber,
      currentSort,
    } = localState;


    dispatch(codeProductsActions.loadCodeProducts(
      filters,
      currentSearch,
      currentPageSize,
      currentPageNumber,
      currentSort,
    ));
  }, [
    codeProductsActions.loadCodeProducts,
    localState.currentSearch,
    localState.currentPageSize,
    localState.currentPageNumber,
    localState.currentSort,
    localState.currentSearch,
  ]);

  useEffect(() => {
    getCodeProducts();
  }, [
    localState.currentPageSize,
    localState.currentPageNumber,
    localState.currentSort,
    localState.currentSearch,
    location,
    size,
  ]);

  const sortCodeProducts = useCallback((name) => {
    const { currentSort: { sortBy, sortDir } } = localState;
    const newSortDir = {
      [true]: () => 'asc',
      [name === sortBy && sortDir === 'asc']: () => 'desc',
      [name === sortBy && sortDir !== 'asc']: () => 'asc',
    }.true();
    const newSort = { sortBy: name, sortDir: newSortDir };
    localDispatch({ type: componentActions.updateSort, currentSort: newSort });
  }, [localState.currentSort.sortBy, localState.currentSort.sortDir]);

  const renderCodeProducts = () => {
    const codeProductsItems = codeProducts.map((codeProduct, index) => {
      const key = index + 1;
      return (
        <CodeProduct
          key={key}
          history={history}
          codeProduct={codeProduct}
        />
      );
    });

    return codeProductsItems.length > 0
      ? codeProductsItems
      : (
        <NoResults
          id="no-results"
          title={t('msg_codes_codes_noResultsFound')}
          message={t('msg_codes_search_again_error')}
        />
      );
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    localDispatch({ type: componentActions.setFilter, name, value });
  };

  const createFilters = useCallback(() => {
    const {
      codeProductType,
      applicableAgreementType,
      exclusiveToPartner,
      region,
    } = filters;

    const codeProductTypeOptions = filterOptions.codeProductTypeOptions.map((option) => {
      const { value, label } = option;
      const newLabel = t(label);
      return {
        value,
        label: newLabel,
      };
    });

    const applicableAgreementTypeOptions = filterOptions
      .agreementFilterTypeOptions.map((option) => {
        const { value, label } = option;
        const newLabel = t(label);
        return {
          value,
          label: newLabel,
        };
      });

    const partnerFilterOptions = [
      ...map(partners, partner => ({ label: partner.name, value: partner.partnerId })),
    ];

    const regionOptions = filterOptions.regionOptions.map((option) => {
      const { value, label } = option;
      const newLabel = t(label);
      return {
        value,
        label: newLabel,
      };
    });

    return [
      {
        label: t('msg_codes_orderList_codeProductType'),
        name: 'codeProductType',
        placeholder: t('msg_codes_filter_all'),
        multiple: true,
        value: codeProductType,
        options: codeProductTypeOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_agreement_types'),
        name: 'applicableAgreementType',
        placeholder: t('msg_codes_filter_all'),
        value: applicableAgreementType,
        multiple: true,
        options: applicableAgreementTypeOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_exclusive_partner'),
        name: 'exclusiveToPartner',
        placeholder: t('msg_codes_filter_all'),
        value: exclusiveToPartner,
        multiple: true,
        options: partnerFilterOptions,
        handleChange: handleFilterChange,
      },
      {
        label: t('msg_codes_common_region'),
        name: 'region',
        placeholder: t('msg_codes_filter_all'),
        value: region,
        multiple: true,
        options: regionOptions,
        handleChange: handleFilterChange,
      },
    ];
  });

  if (!canViewCodeProducts) {
    return null;
  }

  const actionBar = () => {
    const handleApplyFilters = () => {
      dispatch(codeProductsActions.loadCodeProducts(
        filters,
        localState.currentSearch,
        localState.currentPageSize,
        localState.currentPageNumber,
        localState.currentSort,
      ));
    };

    const clearFilters = () => {
      dispatch(codeProductsActions.loadCodeProducts(
        defaultFilters,
        localState.currentSearch,
        localState.currentPageSize,
        localState.currentPageNumber,
        localState.currentSort,
      ))
        .then(() => {
          localDispatch({ type: componentActions.clearFilters });
        });
    };

    return (
      <ActionBar
        id="code-products-action-bar"
        filters={
          <FiltersContainer filters={createFilters()} />
        }
        onFilterClear={clearFilters}
        filterCount={filterCount}
        onFilterApply={handleApplyFilters}
        filterClearLabel={t('msg_codes_cta_clear')}
        filterPrimaryLabel={t('msg_codes_cta_apply')}
        filterSecondaryLabel={t('msg_codes_cta_cancel')}
        filterToolTipText={t('msg_codes_filter')}
        filterTitle={t('msg_codes_filter')}
        right={canCreateCodeProduct && (
          <Button
            id="new-code-product"
            onClick={() => newCodeProduct()}
            className="new-code-product"
            primary
            icon="ico-plus"
          >
            {t('msg_codes_new_code_product')}
          </Button>
        )}
      />
    );
  };

  return (
    <Container id="code-products-list" className="code-products-list">
      <Layout id="code-products-list-layout">
        <Flex id="code-products-list-flex" colSpan={12}>
          <SearchHeader
            name="codeProducts"
            title={t('msg_codes_code_products')}
            placeholder={t('msg_codes_search_product_name_id')}
            changeSearch={changeSearch}
            canSearch
            clearIcon
          />
        </Flex>
      </Layout>
      <Layout
        className="code-products-table"
        id="code-products-table"
      >
        <Flex
          className="code-products-content"
          id="code-products-content"
        >
          {
            (
              <DataTable
                columnTemplate="repeat(8, 1fr)"
                actionBar={actionBar()}
                loading={loadingCodeProducts}
              >
                <TableHeader id="code-products-list-header">
                  {headerColumns.map((column) => {
                    return (
                      <TableHeaderCell
                        key={column.id}
                        id={column.value}
                        value={column.value}
                        sortable={column.sortable}
                        sort={localState.currentSort}
                        className={`header-cell-${column.value}`}
                        onClick={sortCodeProducts}
                      >
                        {t(column.stringId)}
                      </TableHeaderCell>
                    );
                  })}
                </TableHeader>
                {renderCodeProducts()}
              </DataTable>
            )
          }
          {totalElements > 10 && (
            <div data-testid="code-products-paginator" className="code-products-paginator">
              <RowDisplay
                id="code-products-paginator-selector"
                currentPage={number + 1}
                totalItems={totalElements}
                pageSizes={[10, 25, 50, 100]}
                onPageSizeChange={
                  pageSize => localDispatch({
                    type: componentActions.updatePageSize,
                    pageSize,
                    userId,
                  })
                }
                initialPageSize={size}
                showingOfText={RowDisplayLocalization('msg_codes_pagination_showing')(t)}
              />
              {totalElements > size && (
                <Pagination
                  id="code-products-paginator-navigator"
                  totalRecords={totalElements}
                  currentPage={number + 1}
                  pageLimit={size}
                  onPageItemClick={
                    pageNumber => localDispatch({
                      type: componentActions.updatePageNumber,
                      pageNumber: pageNumber - 1,
                    })
                  }
                />
              )}
            </div>
          )}
        </Flex>
      </Layout>
    </Container>
  );
};

CodeProductsCatalogPage.propTypes = {
  location: PropTypes.object,
};

CodeProductsCatalogPage.defaultProps = {
  location: {},
};

export default CodeProductsCatalogPage;
