import React, {
  useEffect,
  useState,
} from 'react';
import {
  Route,
  Router,
  Switch,
  useLocation,
} from 'react-router-dom';
import {
  withTranslation,
  useTranslation,
} from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationsContainer, ModalContainer, AlertContainer } from '@partner-global-ui/components';
import PropTypes from 'prop-types';
import '@partner-global-ui/components/dist/styles.min.css';
import Orders from './components/orders/OrdersPage';
import OrderDetailPageSelector from './components/order/OrderDetailPageSelector';
import Catalog from './components/catalog/CatalogPage';
import Partners from './components/partners/PartnersPage';
import Partner from './components/partner/PartnerPage';
import AgreementDetailPageSelector from './components/agreement/AgreementDetailPageSelector';
import Agreements from './components/agreements/AgreementsPage';
import Users from './components/users/UsersPage';
import User from './components/user/UserPage';
import ThirdPartyCatalog from './components/thirdPartyCatalog/catalog/ThirdPartyCatalogPage';
import Cart from './components/thirdPartyCatalog/cart/cart';
import OrderHistory from './components/thirdPartyCatalog/orders/OrderHistory';
import VoucherTypes from './components/vouchertypes/VoucherTypesPage';
import VoucherBatches from './components/voucherBatches/VoucherBatchesPage';
import CodeProducts from './components/codeProducts/CodeProductsPage';
import VoucherTypeDetail from './components/voucherType/VoucherTypeDetailPage';
import codeProduct from './components/codeProduct/CodeProduct';
import TosReferencePage from './components/termsOfService/TosReferencePage';
import NeedPGPSetupPage from './components/needPGPSetup/NeedPGPSetup';
import OrderSuccessPage from './components/orderSuccess/orderSuccess';
import CanceledTos from './components/error/CanceledTos';
import NotFoundError from './components/error/NotFound';
import NotAuthorized from './components/error/NotAuthorized';
import VoucherBatchDetail from './components/voucherBatch/VoucherBatchDetailPage';
import Maintainance from './components/common/maintainancePage/maintainancePage';
import MaintainanceDashboard from './components/maintainanceDashboard/maintenanceDashboard';
import OrderEmailAction from './components/confirmation/OrderEmailActionPage';
import oktaConfig from './api/oktaConfig';
import history from './history';
import './App.scss';
import * as appActions from './actions/appActions';
import * as voucherProductActions from './actions/voucherProductActions';
import * as orderLineCacheActions from './actions/orderLineCacheActions';
import * as partnerActionsImport from './actions/partnerActions';
import * as callbackGatewayImport from './actions/callbackGatewayActions';
import * as cartProductsActions from './actions/cartProductsActions';
import * as appConfigActionsImport from './actions/appConfigActions';
import AuthenticatedRouteComponent from './components/security/AuthenticatedRoute';
import {
  createGlobalSession, getIdToken, isLoginNotRequired, logout, ssoLogin,
} from './components/security/okta';
import hasPermission, { userPermission } from './utils/accessControl/hasPermission';
import componentMapping from './components/security/componentMapping';
import LoadingWrapper from './components/layouts/loadingWrapper';
import roleKeys from './utils/accessControl/roleKeys';
import useScript from './hooks/useScript';
import getFeature from './utils/accessControl/getFeature';
import createPageTitle from './utils/createPageTitle';

const Analytics = () => {
  const source = getFeature('adobe');
  const status = useScript(source);

  useEffect(() => {
    window.digitalData = {};
  }, []);

  if (status === 'ready' && typeof window._satellite !== 'undefined') {
    window._satellite.pageBottom();
  }

  /**
  * Must have functional component to use hooks
  * This can be removed and logic moved into App if it is converted into a functional component
  */
  return <div style={{ display: 'none' }} />;
};

const HeaderUpdates = () => {
  const location = useLocation();
  const { pathname } = location;

  const { t } = useTranslation();

  // Set page title based on the path
  useEffect(() => {
    document.title = createPageTitle(pathname, t);
  }, [pathname]);

  return <div style={{ display: 'none' }} />;
};

function App(props) {
  const [loaded, setLoaded] = useState(false);
  const {
    actions, app,
    callbackGatewayActions,
    lineActions: { loadRemovedOrderLineCache },
    loginActions,
    voucherProductsActions,
    t,
    cartActions,
    appConfigActions, store, user,
  } = props;

  useEffect(() => {
    actions.loadApp();
  }, []);

  useEffect(() => {
    const { loadAppConfig } = appConfigActions;
    const { loadVoucherProductTypes } = voucherProductsActions;
    const { loadCallbackGateways } = callbackGatewayActions;

    if (app) {
      sessionStorage.setItem('startupTime', new Date());
      const { clientId, environmentName, signInUrl } = oktaConfig;
      createGlobalSession(
        clientId,
        environmentName,
        signInUrl,
        window.location.origin,
      ).then(() => {
        if (isLoginNotRequired()) {
          setTimeout(() => {
            setLoaded(true);
          },
          1000);
          return;
        }
        getIdToken().then((token) => {
          loginActions.ssoLogin(token).then((response) => {
            const canSeeCallbackGateway = userPermission(response.user, roleKeys.access.callbackGateway, '');
            if (response.httpStatus === 'UNAUTHORIZED') {
              console.error(t('unauthorized'));
              logout();
              setLoaded(true);
            } else {
              const readyToLoad = !response.user.mustAcceptCurrentTerms
                ? [
                  loadAppConfig(),
                  loadRemovedOrderLineCache(),
                  loadVoucherProductTypes('agency'),
                  canSeeCallbackGateway ? loadCallbackGateways() : Promise.resolve(),
                ] : [];
              cartActions.loadCartFromStorage(response.user.userId);
              Promise.all(readyToLoad).then(() => {
                setLoaded(true);
                if (response.user.mustSetupEncryptionKey) {
                  history.push('/needpgpsetup');
                }
              });
            }
          });
        });
      });
    }
  }, [app]);


  if (typeof app.version === 'undefined') {
    return null;
  }

  /* eslint-disable quote-props */
  const pages = {
    Orders,
    ThirdPartyCatalogPage: ThirdPartyCatalog,
    VoucherTypes,
  };
    /* eslint-enable quote-props */

  const renderMainPage = () => {
    const mainPageOverride = getFeature('mainPageOverride');
    const action = componentMapping('/');

    const homePage = hasPermission(store, action);
    if (
      mainPageOverride
        && typeof mainPageOverride.component === 'string'
    ) {
      if (hasPermission(store, mainPageOverride.permission)) {
        return pages[mainPageOverride.component];
      }
      return NotAuthorized;
    }
    return pages[homePage];
  };

  return (
    <LoadingWrapper loading={!loaded} minTimeForLoadDisplay={0} loadingClassName="app-auth-loading" className="codes">
      <Router history={history}>
        <ModalContainer>
          <AlertContainer>
            <NotificationsContainer>
              <Analytics />
              <HeaderUpdates />
              <Switch>
                <AuthenticatedRouteComponent store={store} user={user} path="/" exact component={renderMainPage()} />
                <AuthenticatedRouteComponent store={store} user={user} path="/orders" exact component={Orders} />
                <AuthenticatedRouteComponent store={store} user={user} path="/order" exact component={OrderDetailPageSelector} />
                <AuthenticatedRouteComponent store={store} user={user} path="/order/:id" component={OrderDetailPageSelector} />
                <AuthenticatedRouteComponent store={store} user={user} path="/partners" exact component={Partners} />
                <AuthenticatedRouteComponent store={store} user={user} path="/partner" exact component={Partner} />
                <AuthenticatedRouteComponent store={store} user={user} path="/partner/:id" component={Partner} />
                <AuthenticatedRouteComponent store={store} user={user} path="/agreements" exact component={Agreements} />
                <AuthenticatedRouteComponent store={store} user={user} path="/agreement" exact component={AgreementDetailPageSelector} />
                <AuthenticatedRouteComponent store={store} user={user} path="/agreement/:id" component={AgreementDetailPageSelector} />
                <AuthenticatedRouteComponent store={store} user={user} path="/add-lines/:id" component={Catalog} />
                <AuthenticatedRouteComponent store={store} user={user} path="/users" component={Users} />
                <AuthenticatedRouteComponent store={store} user={user} path="/user" exact component={User} />
                <AuthenticatedRouteComponent store={store} user={user} path="/user/:id" component={User} />
                <AuthenticatedRouteComponent store={store} user={user} path="/thirdPartyCatalog" component={ThirdPartyCatalog} />
                <AuthenticatedRouteComponent store={store} user={user} path="/vouchertypes" component={VoucherTypes} />
                <AuthenticatedRouteComponent store={store} user={user} path="/voucherbatches" component={VoucherBatches} />
                <AuthenticatedRouteComponent store={store} user={user} path="/codeproducts" component={CodeProducts} />
                <AuthenticatedRouteComponent store={store} user={user} path="/vouchertype/:id" component={VoucherTypeDetail} />
                <AuthenticatedRouteComponent store={store} user={user} path="/codeproduct/:id" component={codeProduct} />
                <AuthenticatedRouteComponent store={store} user={user} path="/codeproduct" component={codeProduct} />
                <AuthenticatedRouteComponent store={store} user={user} path="/tos" component={TosReferencePage} />
                <AuthenticatedRouteComponent store={store} user={user} path="/canceledtos" component={CanceledTos} />
                <AuthenticatedRouteComponent store={store} user={user} path="/needpgpsetup" component={NeedPGPSetupPage} />
                <AuthenticatedRouteComponent store={store} user={user} path="/ordersuccess" component={OrderSuccessPage} />
                <AuthenticatedRouteComponent store={store} user={user} path="/cart" component={Cart} />
                <AuthenticatedRouteComponent store={store} user={user} path="/orderhistory" component={OrderHistory} />
                <AuthenticatedRouteComponent store={store} user={user} path="/voucherbatch/:id" component={VoucherBatchDetail} />
                <AuthenticatedRouteComponent store={store} user={user} path="/maintainance" component={MaintainanceDashboard} />
                <Route store={store} user={user} path="/order-action" exact component={OrderEmailAction} />
                <Route path="/down" component={Maintainance} />
                <Route path="*" component={NotFoundError} />
              </Switch>
            </NotificationsContainer>
          </AlertContainer>
        </ModalContainer>
      </Router>
    </LoadingWrapper>
  );
}

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(appActions, dispatch),
    voucherProductsActions: bindActionCreators(voucherProductActions, dispatch),
    lineActions: bindActionCreators(orderLineCacheActions, dispatch),
    partnerActions: bindActionCreators(partnerActionsImport, dispatch),
    callbackGatewayActions: bindActionCreators(callbackGatewayImport, dispatch),
    loginActions: bindActionCreators({ ssoLogin }, dispatch),
    cartActions: bindActionCreators(cartProductsActions, dispatch),
    appConfigActions: bindActionCreators(appConfigActionsImport, dispatch),
  };
}

App.propTypes = {
  actions: PropTypes.object,
  voucherProductsActions: PropTypes.object,
  lineActions: PropTypes.object,
  loginActions: PropTypes.object,
  cartActions: PropTypes.object,
  callbackGatewayActions: PropTypes.object,
  store: PropTypes.object,
  user: PropTypes.object,
  app: PropTypes.object,
  t: PropTypes.func.isRequired,
  appConfigActions: PropTypes.object,
};

App.defaultProps = {
  actions: {},
  lineActions: {},
  loginActions: {},
  cartActions: {},
  callbackGatewayActions: {},
  voucherProductsActions: {},
  store: {},
  user: {},
  app: {},
  appConfigActions: {},
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));
